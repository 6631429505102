import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { S25Util } from "../../../util/s25-util";
import { TypeManagerDecorator } from "../../../main/type.map.service";
import { S25DatePatternPickerComponent } from "../../s25-datepattern/s25.datepattern.picker.component";
import { DropDownItem } from "../../../pojo/DropDownItem";
import { Blackout, SpaceService } from "../../../services/space.service";
import { Flavor } from "../../../pojo/Util";
import { Event } from "../../../pojo/Event";
import Occurrence = Event.Occurrence;

@TypeManagerDecorator("s25-ng-edit-blackout")
@Component({
    selector: "s25-ng-edit-blackout",
    template: `
        <div class="flexRow">
            <label for="blackoutName">Name</label>
            <input id="blackoutName" type="text" [(ngModel)]="name" class="c-input" />
        </div>
        <div *ngIf="showErrors && !name" class="ngRed">Please enter a name</div>

        <div class="flexRow">
            <label for="blackoutStart" class="lineHeightAdjustment">Start</label>
            <s25-ng-editable-date-time
                [val]="start"
                [fieldID]="'blackoutStart'"
                [allowEmpty]="false"
                (valChange)="onStartDateChange($event)"
            ></s25-ng-editable-date-time>
        </div>

        <div class="flexRow">
            <label for="blackoutEnd" class="lineHeightAdjustment">End</label>
            <s25-ng-editable-date-time
                [val]="end"
                [fieldID]="'blackoutEnd'"
                [allowEmpty]="false"
                (valChange)="onEndDateChange($event)"
            ></s25-ng-editable-date-time>
        </div>

        <s25-ng-duration [start]="start" [end]="end"></s25-ng-duration>

        <div class="flexRow blackout-pattern">
            <s25-ng-date-pattern-picker
                #datePicker
                [profileCode]="pattern"
                [(adHocOccs)]="dates"
                [timeModel]="{
                    evStartDt: start,
                    evEndDt: end,
                    minutes: { setupMinutes: 0, preMinutes: 0, postMinutes: 0, takedownMinutes: 0 },
                }"
                [minDate]="start"
                [editAdHoc]="true"
                [editNonAdHoc]="true"
                [showAdHocList]="false"
                (apiBeanChange)="onDatePatternChange($event)"
            ></s25-ng-date-pattern-picker>
        </div>

        <div class="flexRow">
            <label for="notes">Notes</label>
            <textarea id="notes" [(ngModel)]="notes" class="cn-form__control"></textarea>
        </div>

        <div class="flexRow locations ">
            <label>Locations</label>
            <s25-ng-multiselect-search-criteria
                [type]="'locations'"
                [selectedItems]="locations"
                [popoverOnBody]="true"
                [modelBean]="{ showResult: true }"
                [customFilterValue]="'&min_ols=C'"
                (changed)="locations = $event"
            ></s25-ng-multiselect-search-criteria>
        </div>

        <div *ngIf="showErrors && !locations?.length" class="ngRed">Please select at least one location</div>

        <s25-ng-modal-footer [type]="'save'" (save)="save($event)" (cancel)="cancel()"></s25-ng-modal-footer>
    `,
})
export class S25EditBlackoutComponent implements OnInit {
    @Input() isNew: boolean;
    @Input() blackout: Blackout;

    @Output() saved = new EventEmitter<void>();
    @Output() close = new EventEmitter<void>();

    @ViewChild("datePicker") datePicker: S25DatePatternPickerComponent;

    name = "New Blackout";
    pattern: Flavor<string, "repeating pattern">;
    dates: Occurrence[] = [];
    start = S25Util.date.toStartOfHour(new Date());
    end = S25Util.date.addHours(S25Util.date.toStartOfHour(new Date()), 1);
    showErrors = false;
    adHocApiBean: any = {}; // Needed for communication between date pattern picker and calendar
    locations: DropDownItem[];
    notes: string;

    constructor(private changeDetector: ChangeDetectorRef) {}

    ngOnInit() {
        if (this.blackout) {
            this.name = this.blackout.profile_name;
            this.pattern = this.blackout.profile_code;
            if (this.blackout.date?.length > 1 && !this.blackout.profile_code) this.pattern = "adhoc";
            this.dates =
                this.blackout.date?.map((date) => ({
                    evStartDt: new Date(date.start_dt),
                    evEndDt: new Date(date.end_dt),
                })) || [];
            this.start = new Date(this.blackout.init_start_dt);
            this.end = new Date(this.blackout.init_end_dt);
            this.notes = this.blackout.comment;
            this.locations = (this.blackout.room || []).map((room) => ({
                itemId: room.room_id,
                itemName: room.room_short,
            }));

            if (this.isNew) this.blackout = undefined; // Forget original configuration for copy
        }
    }

    onStartDateChange(date: Date) {
        // When changing start date, also move end date by the same amount
        this.end = S25Util.date.addDays(this.end, S25Util.date.diffDays(this.start, date));

        this.start = date;
        this.onDateChange();

        this.datePicker.changeFirstDt(date);
    }

    onEndDateChange(date: Date) {
        this.end = date;
        this.onDateChange();
    }

    onDateChange() {
        // Make sure start and end dates are valid
        if (this.start > this.end) {
            // If the time is larger, we default end to be an hour after start
            if (S25Util.date.dateToHours(this.start) > S25Util.date.dateToHours(this.end)) {
                this.end = S25Util.date.addHours(S25Util.date.clone(this.start), 1);
            }
            // Otherwise, we just need to update the date
            else {
                this.end = S25Util.date.addDays(this.end, S25Util.date.diffDays(this.end, this.start));
            }
        }
        this.changeDetector.detectChanges();
    }

    onDatePatternChange(apiBean: any) {
        this.pattern = apiBean.getProfileCode();
        this.changeDetector.detectChanges();
    }

    getDatesFromOccs(occs: (Date | { evStartDt: Date; evEndDt: Date })[]) {
        return S25Util.array.flatten(
            occs.map((occ) => {
                if (occ instanceof Date) return occ;
                const dates = [];
                let date = S25Util.date.toStartOfDay(occ.evStartDt);
                const end = S25Util.date.toEndOfDay(occ.evEndDt);
                while (date < end) {
                    dates.push(date);
                    date = S25Util.date.addDays(S25Util.date.clone(date), 1);
                }
                return dates;
            }),
        );
    }

    onDatePickerChange() {
        // When we update the selected dates from the date picker, change pattern to ad-hoc
        this.datePicker?.setPatternType("Ad hoc");
    }

    async save(stop: () => void) {
        this.showErrors = true;
        if (!this.name || !this.locations?.length) return stop();
        let profileCode = this.datePicker.getProfileCode();
        const occs = this.datePicker.getOccurrences();
        const recType = occs.length === 1 ? 0 : profileCode === "adhoc" ? 2 : 1;
        if (["dnr", "adhoc"].includes(profileCode)) profileCode = "";

        const firstOcc = occs.reduce((a, b) => (a.evStartDt < b.evStartDt ? a : b));

        const [ok, error] = await S25Util.Maybe(
            SpaceService.putBlackout(
                this.blackout?.profile_name,
                this.blackout?.profile_code || "",
                this.blackout?.init_start_dt,
                this.blackout?.init_end_dt,
                this.name,
                S25Util.date.toS25ISODateTimeStr(firstOcc.evStartDt),
                S25Util.date.toS25ISODateTimeStr(firstOcc.evEndDt),
                profileCode,
                recType,
                this.locations.map((loc) => loc.itemId as number),
                occs.map((occ) => {
                    const start: Date = occ.evStartDt || (occ as any);
                    const end: Date = occ.evEndDt || (occ as any);
                    let start_dt = S25Util.date.toS25ISODateTimeStr(start);
                    let end_dt = S25Util.date.toS25ISODateTimeStr(end);
                    if (recType === 2) {
                        start_dt = S25Util.date.toS25ISODateTimeStr(S25Util.date.copyTime(new Date(start), this.start));
                        end_dt = S25Util.date.toS25ISODateTimeStr(S25Util.date.copyTime(new Date(end), this.end));
                    }
                    return { start_dt, end_dt };
                }),
                this.notes,
            ),
        );
        if (error) {
            if (error.status === 403) {
                return alert(`Lacking adequate permissions to ${this.isNew ? "create" : "edit"} this blackout.`);
            }
            S25Util.showError(error, "Something went wrong saving blackout.");
            return;
        }

        this.saved.emit();
        this.close.emit();
    }

    cancel() {
        this.close.emit();
    }
}
