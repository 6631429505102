import { ValueOf } from "../../../pojo/Util";

export namespace Doc {
    export type Scope = ValueOf<typeof scope>;
    export type ScopeId = Scope["id"];
    export type ScopeReportId = Scope["reportId"];

    export const scope = {
        event: {
            id: "event",
            label: "Event Based",
            order: 0,
            reportId: -198,
            type: "standard",
        },
        reservation: {
            id: "reservation",
            label: "Reservation Based",
            order: 1,
            reportId: -210,
            type: "standard",
        },
        organization: {
            id: "organization",
            label: "Organization Based",
            order: 2,
            reportId: -217,
            type: "standard",
        },
        eventListing: {
            id: "eventListing",
            label: "Event Listing - Excel",
            order: 3,
            reportId: -228,
            type: "tableBuilder",
            tableBuilderColumnsKey: "eventListing",
            tableBuilderVariable: "ws.flexTable.events",
        },
        rsrvListing: {
            id: "rsrvListing",
            label: "Reservations By Date - Excel",
            order: 4,
            reportId: -229,
            type: "tableBuilder",
            tableBuilderColumnsKey: "reservationListing",
            tableBuilderVariable: "ws.flexTable.rsrvsByDate",
        },
        locListing: {
            id: "locListing",
            label: "Location Listing - Excel",
            order: 5,
            reportId: -230,
            type: "tableBuilder",
            tableBuilderColumnsKey: "locationListing",
            tableBuilderVariable: "ws.flexTable.locations",
        },
        resListing: {
            id: "resListing",
            label: "Resource Listing - Excel",
            order: 6,
            reportId: -231,
            type: "tableBuilder",
            tableBuilderColumnsKey: "resourceListing",
            tableBuilderVariable: "ws.flexTable.resources",
        },
        orgListing: {
            id: "orgListing",
            label: "Organization Listing - Excel",
            order: 7,
            reportId: -232,
            type: "tableBuilder",
            tableBuilderColumnsKey: "organizationListing",
            tableBuilderVariable: "ws.flexTable.organizations",
        },
    } as const;
    export const scopeOptions = Object.values(scope).sort((a, b) => a.order - b.order);

    export type ScopeOptions = typeof scopeOptions;

    // Parses the columns of a table builder table variable
    // Format: {{for: <table variable> : <column1> || <column2> || ... :end-for}}
    // Columns: <variable>.<id> | <format> : <header> : <width>
    // Where only variable and header are required
    export const tableBuilderColumnRegex =
        /(?<variable>[^:|.]+)(?:\.(?<id>-?\d+))?\s*(?:\|\s*(?<format>[^:|]+)\s*)?:\s*(?<header>[^:|]+)\s*(?::\s*(?<width>\d+)\s*)?\s*(?:\|\||:end-for)/gi;
}
